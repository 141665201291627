import React, { useState } from 'react';
import { Button, SwipeableDrawer, useTheme } from '@mui/material';
import FilterBar from '../filterbar/FilterBar';
import FilterSvg from '../../asset/image/filter.svg';

const FilterDrawer = () => {
  const [isOpen, setIsOpen] = useState(false);
  const theme = useTheme();

  const toggleDrawer = (open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setIsOpen(open);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <div className="DownloadReport">
      <Button
        variant="outlined"
        size="large"
        startIcon={<img src={FilterSvg} alt="Filter" width={"35px"}/>}
        onClick={toggleDrawer(true)}
        sx={{
          color: theme.palette.textcolors.headerplaningitemtext,
          border: theme.palette.borderClr.reportDownload,
          backgroundColor: theme.palette.background.reportDownload,
        }}
      >
        Filters
      </Button>
      <SwipeableDrawer
        anchor="top"
        open={isOpen}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        BackdropProps={{
          sx: {
            backdropFilter: 'blur(5px)',
          }
        }}
      >
        <FilterBar onClose={handleClose} />
      </SwipeableDrawer>
    </div>
  );
};

export default FilterDrawer;