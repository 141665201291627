import { useState, useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import moment from 'moment';

moment.tz.setDefault("America/New_York");

export const useLastUpdateTime = () => {
    const [lastUpdateTime, setLastUpdateTime] = useState(moment());
    const queryClient = useQueryClient();

    useEffect(() => {
        const unsubscribe = queryClient.getQueryCache().subscribe(() => {
            setLastUpdateTime(moment());
        });

        return () => unsubscribe();
    }, [queryClient]);

    return lastUpdateTime;
};