import React from "react";
import { Box, Typography, useTheme } from "@mui/material";
import Ringing from "../../asset/image/call-ringing-animation.gif";
import CalenderImg from '../../asset/image/clock_theme.svg';
const PlanningItem = ({ title, value, spanColor }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.background.headerplaningitem,
        boxShadow: theme.customShadows.headerplaningitem,
      }}
      className="header_planing_item bgThemeText"
    >
      <div className="header_planing_item_icon">
        <img src={Ringing} alt="Icon" />
      </div>
      <div className="header_planing_item_text">
        <Typography
          variant="span"
          sx={{ color: theme.palette.textcolors.headerplaningitemtext }}
          className="planning_item_strong"
        >
          {" "}
          {title}
        </Typography>
        <Typography variant="span" sx={{ color: spanColor }}>
          {value}
        </Typography>
      </div>
    </Box>
  );
};

const PlanningBox = ({ metrics }) => {
  const theme = useTheme();
  return (
    <div className="header_planing_box">
      <div className="header_planing_box_icon">
        <img src={Ringing} alt='' />
      </div>
      <PlanningItem
        title="Live"
        spanColor={theme.palette.textcolors.spanclr}
        value={metrics?.live_calls || 0}
      />
      <PlanningItem
        title="Incoming"
        spanColor={theme.palette.textcolors.spanclr2}
        value={metrics?.incoming_calls || 0}
      />
      <PlanningItem
        title="Converted"
        spanColor={theme.palette.textcolors.spanclr}
        value={metrics?.converted_calls || 0}
      />
    </div>
  );
};

export default PlanningBox;
