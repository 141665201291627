import React, { createContext, useState, useContext, useEffect } from 'react';
import { useUpdatePreference, useGetPreferences } from '../hooks/api/usePreference';
import { useGetUniqueVerticals, useGetUniqueTrafficPlatformSources, useGetUniqueAgencies, useGetUniqueMediaBuyers } from '../hooks/api/useCampaign';

const FilterContext = createContext();

export const FilterProvider = ({ children }) => {
    const { data, refetch } = useGetPreferences();
    const preferenceData = data?.data?.data;

    const { data: verticals } = useGetUniqueVerticals();
    const { data: trafficPlatformSources } = useGetUniqueTrafficPlatformSources();
    const { data: agencies } = useGetUniqueAgencies();
    const { data: mediaBuyers } = useGetUniqueMediaBuyers();

    const updatePreferences = useUpdatePreference({
        onSuccess: () => {
            setTimeout(() => {
                refetch();
            }, 750);
        },
        onError: (error) => {
            console.error("Error updating preferences:", error);
        }
    });

    const [filters, setFilters] = useState({
        media_buyer: [],
        vertical: [],
        traffic_platform_source: [],
        agency: []
    });

    useEffect(() => {
        if (preferenceData && preferenceData[0]) {
            const filter_configs = preferenceData[0].filter_configs || {
                media_buyer: [],
                vertical: [],
                traffic_platform_source: [],
                agency: []
            };

            const result = {
                media_buyer: filterMatchingIds(filter_configs.media_buyer, mediaBuyers?.data?.data),
                vertical: filterMatchingIds(filter_configs.vertical, verticals?.data?.data),
                traffic_platform_source: filterMatchingIds(filter_configs.traffic_platform_source, trafficPlatformSources?.data?.data),
                agency: filterMatchingIds(filter_configs.agency, agencies?.data?.data),
            };

            setFilters(result);
        }
    }, [preferenceData, mediaBuyers, verticals, trafficPlatformSources, agencies]);

    const filterMatchingIds = (preferenceIds, apiDataArray) => {
        if (!Array.isArray(preferenceIds) || !Array.isArray(apiDataArray)) {
            return [];
        }
        const apiIds = apiDataArray.map(item => item.id).filter(Boolean);
        const cleanedPreferenceIds = preferenceIds.map(id => Number(id)).filter(id => !isNaN(id));
        return apiIds.filter(id => cleanedPreferenceIds.includes(id));
    };

    const updateFilters = (newFilters) => {
        if (Object.keys(newFilters)?.length === 0) {
            const resetFilters = {
                media_buyer: [],
                vertical: [],
                traffic_platform_source: [],
                agency: [],
            };
            setFilters(resetFilters);
            updatePreferences.mutate({ filter_configs: resetFilters });
        } else {
            setFilters(prevFilters => {
                const updatedFilters = {
                    ...prevFilters,
                    ...newFilters,
                };
                updatePreferences.mutate({ filter_configs: updatedFilters });
                return updatedFilters;
            });
        }
    };

    return (
        <FilterContext.Provider value={{ filters, updateFilters }}>
            {children}
        </FilterContext.Provider>
    );
};

export const useFilters = () => useContext(FilterContext);