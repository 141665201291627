import { useQuery, useMutation } from "@tanstack/react-query";
import { useSearchParams } from "react-router-dom";
import { campaignApi } from "../../api/endpoints/campaignApi";
import { useDateRange } from "../../context/DateRangeContext";

const useDateRangeParams = () => {
  const { dateRange } = useDateRange();
  const [startDate, endDate] = dateRange || [];
  return {
    start_date: startDate?.format("YYYY-MM-DD"),
    end_date: endDate?.format("YYYY-MM-DD"),
  };
};

const useUrlFilters = () => {
  const [searchParams] = useSearchParams();
  const filters = {};
  for (const [key, value] of searchParams.entries()) {
    const decodedValue = decodeURIComponent(value);
    if (decodedValue.includes(',')) {
      filters[key] = decodedValue.split(',');
    } else {
      if (!filters[key]) {
        filters[key] = [];
      }
      filters[key].push(decodedValue);
    }
  }
  return filters;
};

export const useGetPnlsCampaigns = (options = {}) => {
  const dateParams = useDateRangeParams();
  const filters = useUrlFilters();

  const queryParams = {
    ...dateParams,
    ...filters,
  };

  return useQuery({
    queryKey: ["pnls-campaigns", queryParams],
    queryFn: () => campaignApi.getPnlsCampaigns(queryParams),
    enabled: !!dateParams.start_date && !!dateParams.end_date,
    ...options,
  });
};

export const useGetTrendCampaigns = (options = {}) => {
  const dateParams = useDateRangeParams();
  const filters = useUrlFilters();

  const queryParams = {
    date: dateParams.start_date,
    ...filters,
  };

  return useQuery({
    queryKey: ["trend-campaigns", queryParams],
    queryFn: () => campaignApi.getTrendCampaigns(queryParams),
    enabled: !!dateParams.start_date,
    ...options,
  });
};

export const useGetUniqueVerticals = (options = {}) => {
  const dateParams = useDateRangeParams();
  return useQuery({
    queryKey: ["verticals", dateParams],
    queryFn: () => campaignApi.getUniqueVerticals(dateParams),
    enabled: !!dateParams.start_date && !!dateParams.end_date,
    ...options,
  });
};

export const useGetUniqueTrafficPlatforms = (options = {}) => {
  const dateParams = useDateRangeParams();
  return useQuery({
    queryKey: ["traffic-platforms", dateParams],
    queryFn: () => campaignApi.getUniqueTrafficPlatforms(dateParams),
    enabled: !!dateParams.start_date && !!dateParams.end_date,
    ...options,
  });
};

export const useGetUniqueTrafficPlatformSources = (options = {}) => {
  const dateParams = useDateRangeParams();
  return useQuery({
    queryKey: ["traffic-platform-sources", dateParams],
    queryFn: () => campaignApi.getUniqueTrafficPlatformSources(dateParams),
    enabled: !!dateParams.start_date && !!dateParams.end_date,
    ...options,
  });
};

export const useGetUniqueAgencies = (options = {}) => {
  const dateParams = useDateRangeParams();
  return useQuery({
    queryKey: ["agencies", dateParams],
    queryFn: () => campaignApi.getUniqueAgencies(dateParams),
    enabled: !!dateParams.start_date && !!dateParams.end_date,
    ...options,
  });
};

export const useGetUniqueMediaBuyers = (options = {}) => {
  const dateParams = useDateRangeParams();
  return useQuery({
    queryKey: ["media-buyers", dateParams],
    queryFn: () => campaignApi.getUniqueMediaBuyers(dateParams),
    enabled: !!dateParams.start_date && !!dateParams.end_date,
    ...options,
  });
};

export const useGetMetrics = (options = {}) => {
  const dateParams = useDateRangeParams();
  const filters = useUrlFilters();

  const queryParams = {
    ...dateParams,
    ...filters,
  };

  return useQuery({
    queryKey: ["dash-metrics", queryParams],
    queryFn: () => campaignApi.getMetrics(queryParams),
    enabled: !!dateParams.start_date && !!dateParams.end_date,
    ...options,
  });
};

export const useGetHourlyTrends = (options = {}) => {
  const dateParams = useDateRangeParams();
  const filters = useUrlFilters();

  const queryParams = {
    ...dateParams,
    ...filters,
  };

  return useQuery({
    queryKey: ["dash-hourly-trends", queryParams],
    queryFn: () => campaignApi.getHourlyTrends(queryParams),
    enabled: !!dateParams.start_date && !!dateParams.end_date,
    ...options,
  });
};

export const useGetMediaBuyers = (options = {}) => {
  return useQuery({
    queryKey: ["media-buyers"],
    queryFn: () => campaignApi.getMediaBuyers(),
    ...options,
  });
};

export const useGetVerticals = (options = {}) => {
  return useQuery({
    queryKey: ["verticals"],
    queryFn: () => campaignApi.getVerticals(),
    ...options,
  });
};

export const useUpdateCampaignBudget = (options = {}) => {
  return useMutation({
    mutationFn: (data) => campaignApi.updateCampaignBudget(data),
    ...options,
  });
};

export const useUploadCampaignCsv = (options = {}) => {
  return useMutation({
    mutationFn: (data) => {
      campaignApi.uploadCampaignCsv(data);
    },
    ...options,
  });
};

export const useUpdateCampaignsManually = (options = {}) => {
  return useMutation({
    mutationFn: (data) => campaignApi.updateCampaignsManually(data),
    ...options,
  });
};

export const useUpdateCampaignsConfig= (options = {}) => {
  return useMutation({
    mutationFn: (data) => campaignApi.updateCampaignsConfig(data),
    ...options,
  });
};