import React, { createContext, useState, useContext } from 'react';
import moment from 'moment-timezone';

moment.tz.setDefault("America/New_York");

const DateRangeContext = createContext();

export const DateRangeProvider = ({ children }) => {
    const [dateRange, setDateRange] = useState([
        moment(),
        moment()
    ]);

    return (
        <DateRangeContext.Provider value={{ dateRange, setDateRange }}>
            {children}
        </DateRangeContext.Provider> 
    );
};

export const useDateRange = () => useContext(DateRangeContext);