import React, { useState } from 'react';
import DateTimeDisplay from './DateTimeDisplay.jsx';
import PlanningBox from './PlanningBox.jsx';
import FilterDrawer from './FilterDrawer.jsx';
import DateRangePicker from './DateRangePicker.jsx';
import UserMenu from './UserMenu.jsx';
import { Box, useTheme, Typography } from "@mui/material";
import Logo from "../../asset/image/logo.svg";
import { useGetMetrics } from '../../hooks/api/useCampaign.js';

const Header = () => {

  const [menuToggle, setMenuToggle] = useState(false);
  const userString = localStorage.getItem('user');
  const user = JSON.parse(userString);
  const theme = useTheme();

  const { data: metricsData, isLoading: isMetricsDataLoading } = useGetMetrics();
  if (isMetricsDataLoading) return <></>

  const metrics = metricsData?.data?.data

  const funToggleMenu = () => {
    if (menuToggle === true) {
      setMenuToggle(false);
      document.body.classList.add('bt-menu-open');
    }
    else {
      setMenuToggle(true);
      document.body.classList.remove('bt-menu-open');
    }
  }

  return (
    <>
      <header>
        <Box className="header_wrapper"
          sx={{ backgroundColor: theme.palette.background.headerColor }} >
          <div className="header-inner">
            <div className="right_header_part">
              <HeaderTitle user={user} />
              <div className="right_header_part_date">
                <DateTimeDisplay />
                <PlanningBox metrics={metrics} />
              </div>
            </div>
            <div className="header_right_bar">
              <span class="bt-menu-trigger" onClick={() => funToggleMenu()} ><span>Menu</span></span>
              <div className="DownloadReportAndFilter">
                <FilterDrawer />
                <DateRangePicker />
              </div>
              <UserMenu />
            </div>
          </div>
        </Box>
      </header >
      <span className="menu_overly" onClick={() => funToggleMenu()}></span>
    </>
  );
};

const HeaderTitle = ({ user }) => {
  const theme = useTheme();
  return (
    <div className="right_header_part_title">
      <h1 className="m-0">  <img src={Logo} alt="Mission Control" /> AdSync Dash</h1>
      <Typography
        variant="span"
        sx={{ color: theme.palette.textcolors.headerdatetimeboxright }}
      >
        Hello {user?.first_name}!
      </Typography>
    </div>
  );
};

export default Header;