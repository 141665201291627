import axios from 'axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;

const baseApi = axios.create({
    baseURL,
    headers: {
        'Content-Type': 'application/json',
    },
});

baseApi.interceptors.request.use((config) => {
    const token = localStorage.getItem('authToken');
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});

baseApi.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error.response) {
            const errorMessage = error.response.data?.message || 'Unknown error occurred';
            const errorCode = error.response.status;

            if (errorCode === 401) {
                if (localStorage.getItem('authToken')) {
                    localStorage.removeItem('authToken');
                    window.location.href = '/';
                }
            }

            return Promise.reject({ message: errorMessage, code: errorCode });
        } else if (error.request) {
            console.error('Request error:', error.request);
            return Promise.reject({ message: 'Request error: No response received', code: 0 });
        } else {
            console.error('Request setup error:', error.message);
            return Promise.reject({ message: `Request setup error: ${error.message}`, code: 0 });
        }
    }
);

export default baseApi;