import React from 'react';
import Header from "../header/Header";
import Sidebar from "../sidebar/Sidebar";
import { Box, useTheme } from "@mui/material";

const PrivateLayout = ({ children }) => {
    const theme = useTheme();
    return (
        <div className="App">
            <div className="page-layout">
                <Box
                    sx={{ backgroundColor: theme.palette.background.headerColor }}
                    className="page-layout-left"
                >
                    <Sidebar />
                </Box>
                <div className="page-layout-right">
                    <Header />
                    <Box
                        sx={{ backgroundColor: theme.palette.background.pageContent }}
                        className="page-content"
                    >
                        {children}
                    </Box>
                </div>
            </div> 
        </div>
    )
};

export default PrivateLayout;