import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment-timezone';
import { useLastUpdateTime } from '../../hooks/useLastUpdateTime';
import { Typography, useTheme } from "@mui/material";
import { Box } from '@mui/system';
import CalenderImg from '../../asset/image/clock_theme.svg';

const DateTimeDisplay = () => {
    const [time, setTime] = useState(moment().tz('America/New_York'));
    const [timeSinceLastUpdate, setTimeSinceLastUpdate] = useState('0m 0s ago');
    const lastUpdateTime = useLastUpdateTime();
    const theme = useTheme();
    const timerRef = useRef(null);
    const lastUpdateTimeRef = useRef(lastUpdateTime);

    useEffect(() => {
        const updateTime = () => {
            const now = moment().tz('America/New_York');
            setTime(now);

            const duration = moment.duration(now.diff(lastUpdateTimeRef.current));
            const minutes = duration.minutes();
            const seconds = duration.seconds();

            setTimeSinceLastUpdate(`${minutes}m ${seconds}s ago`);
        };

        if (timerRef.current) {
            clearInterval(timerRef.current);
        }

        timerRef.current = setInterval(updateTime, 1000);

        updateTime();

        return () => {
            if (timerRef.current) {
                clearInterval(timerRef.current);
            }
        };
    }, []); 

    useEffect(() => {
        lastUpdateTimeRef.current = lastUpdateTime;
    }, [lastUpdateTime]);

    const formattedDate = time.format('MMMM D');
    const formattedTime = time.format('h:mm:ss A');

    return (
        <Box className="header_date_timebox">
            <div className="header_date_timebox_left">
                <img src={CalenderImg} alt='' />
            </div>
            <div className="header_date_timebox_right">
                <strong>{formattedDate}</strong>
                <Typography
                    variant="span"
                    sx={{ color: theme.palette.textcolors.headerdatetimeboxright }}
                >
                    {formattedTime}
                </Typography>
            </div>
            <Typography
                variant="span"
                sx={{ color: theme.palette.textcolors.headerdatetimeboxright }}
                className="last_update_time w-100"
            >
                Updated {timeSinceLastUpdate}
            </Typography>
        </Box>
    );
};

export default DateTimeDisplay;