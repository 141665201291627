import { Typography, Box } from '@mui/material';

const NotFound = () => (
  <Box
    display="flex"
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    minHeight="100vh"
  >
    <Typography variant="h1" color="primary">
      404
    </Typography>
    <Typography variant="h5" color="textSecondary">
      Page Not Found
    </Typography>
  </Box>
);

export default NotFound;
